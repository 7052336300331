import React from 'react';
import { RichText } from 'prismic-reactjs';
import { graphql, Link } from 'gatsby';

import {
  Layout,
  Hero,
  Section,
  TitleAndMeta,
  Container,
  Row,
  WorkCard,
  NoteItem,
} from '../components';

import styles from './homepage.module.scss';
import { linkResolver, formatDate, extractExcerpt } from '../utils';

export default ({ data: { prismic } }) => {
  const doc = prismic.allAbouts.edges.slice(0, 1).pop();
  const works = prismic.allWorks.edges;
  const notes = prismic.allNotes.edges;
  if (!doc) return null;

  const page = doc.node;

  return (
    <Layout>
      <TitleAndMeta />
      <Hero image={page.image} title={page.title[0].text}>
        {RichText.render(page.content)}
      </Hero>
      <Section title="Works" className={styles.works}>
        <Container>
          <Row as="ul" wrap>
            {works.map(({ node: work }) => (
              <WorkCard
                to={linkResolver(work)}
                key={work._meta.uid}
                title={work.title[0].text}
              />
            ))}
          </Row>
          <div className={styles.more}>
            <Link to="/works">View More</Link>
          </div>
        </Container>
      </Section>
      <Section title="Notes" titleAlign="right" className={styles.notes}>
        <Container>
          <ul>
            {notes.map(({ node: note }) => {
              const [excerpt, longer] = extractExcerpt(note.content);
              return (
                <NoteItem
                  key={note._meta.uid}
                  title={note.title[0].text}
                  to={linkResolver(note)}
                  date={formatDate(note.publication_date)}
                  showExcerpt={longer}
                >
                  {excerpt}
                </NoteItem>
              );
            })}
          </ul>
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query HomepageQuery {
    prismic {
      allAbouts(first: 1) {
        edges {
          node {
            content
            image
            title
          }
        }
      }
      allWorks(sortBy: publication_date_DESC, first: 3) {
        edges {
          cursor
          node {
            title
            _meta {
              type
              uid
            }
          }
        }
      }
      allNotes(sortBy: publication_date_DESC, first: 5) {
        edges {
          cursor
          node {
            title
            content
            _meta {
              type
              uid
            }
            publication_date
          }
        }
      }
    }
  }
`;
